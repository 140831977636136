// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculators-calendar-js": () => import("./../../../src/pages/calculators/calendar.js" /* webpackChunkName: "component---src-pages-calculators-calendar-js" */),
  "component---src-pages-calculators-car-customs-js": () => import("./../../../src/pages/calculators/car-customs.js" /* webpackChunkName: "component---src-pages-calculators-car-customs-js" */),
  "component---src-pages-calculators-car-sell-js": () => import("./../../../src/pages/calculators/car-sell.js" /* webpackChunkName: "component---src-pages-calculators-car-sell-js" */),
  "component---src-pages-calculators-car-tax-js": () => import("./../../../src/pages/calculators/car-tax.js" /* webpackChunkName: "component---src-pages-calculators-car-tax-js" */),
  "component---src-pages-calculators-currency-js": () => import("./../../../src/pages/calculators/currency.js" /* webpackChunkName: "component---src-pages-calculators-currency-js" */),
  "component---src-pages-calculators-final-js": () => import("./../../../src/pages/calculators/final.js" /* webpackChunkName: "component---src-pages-calculators-final-js" */),
  "component---src-pages-calculators-index-js": () => import("./../../../src/pages/calculators/index.js" /* webpackChunkName: "component---src-pages-calculators-index-js" */),
  "component---src-pages-calculators-mortgage-js": () => import("./../../../src/pages/calculators/mortgage.js" /* webpackChunkName: "component---src-pages-calculators-mortgage-js" */),
  "component---src-pages-calculators-salary-js": () => import("./../../../src/pages/calculators/salary.js" /* webpackChunkName: "component---src-pages-calculators-salary-js" */),
  "component---src-pages-calculators-salary-table-js": () => import("./../../../src/pages/calculators/salary-table.js" /* webpackChunkName: "component---src-pages-calculators-salary-table-js" */),
  "component---src-pages-calculators-subsidy-js": () => import("./../../../src/pages/calculators/subsidy.js" /* webpackChunkName: "component---src-pages-calculators-subsidy-js" */),
  "component---src-pages-calculators-vacation-js": () => import("./../../../src/pages/calculators/vacation.js" /* webpackChunkName: "component---src-pages-calculators-vacation-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-career-single-career-js": () => import("./../../../src/pages/career/singleCareer.js" /* webpackChunkName: "component---src-pages-career-single-career-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-declaration-index-js": () => import("./../../../src/pages/declaration/index.js" /* webpackChunkName: "component---src-pages-declaration-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../../../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-single-news-page-js": () => import("./../../../src/pages/news/singleNewsPage.js" /* webpackChunkName: "component---src-pages-news-single-news-page-js" */),
  "component---src-pages-relocation-index-js": () => import("./../../../src/pages/relocation/index.js" /* webpackChunkName: "component---src-pages-relocation-index-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

